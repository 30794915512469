<!-- 模块说明 -->
<template>
  <div class="container">
    <subHead :active="1" :subpageActive="subpageActive"></subHead>
    <router-view></router-view>
  </div>
</template>

<script>
import subHead from "../../components/subHead.vue";
export default {
  metaInfo: {
    title: "解决方案|灵活用工,人才配置,数字员工",
  },
  components: { subHead },
  props: [],
  data() {
    return {
      subpageActive: 0,
    };
  },
  watch: {
    "$route.query.show": {
      handler(newVal) {
        console.log(newVal);
        this.subpageActive = +newVal || 0;
      },
      deep: true,
    },
  },
  created() {
    this.subpageActive = +this.$route.query.show;
  },
  methods: {},
  computed: {},
};
</script>
<style lang="less" scoped></style>
